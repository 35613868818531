export const services = [
  { 
    id: 1, 
    title: 'PROJECT PLANNING', 
    description: 'We offer comprehensive project planning services, including property sourcing, local surveys, architectural design, building development, planning application assistance, and financial planning for builds.', 
    content: `Many homeowners are finding that it’s more practical to renovate their existing property in order to create their dream home, rather than selling up. Lambert & Wright specialise in property renovations across the East Midlands and have extensive experience in building and renovation projects, helping homeowners bring their vision to life. Our expertise includes architectural design, ground work, and building control advice to ensure your project runs smoothly.\n\nIt’s often a pleasant surprise for homeowners to discover that the cost of renovation may be less than expected. With over 30 years of experience in the East Midlands, our trusted team of architects, tradesmen, and craftsmen ensure the highest standards for your home.\n\nOur services include:\n\n• Project Management: Experienced project managers who oversee your renovation to completion.\n\n• Trusted Suppliers: We work with vetted suppliers for all renovation and restoration materials, including timber, period oak, and modern building materials.\n\n• Expert Tradesmen: We only use highly skilled tradesmen for tasks like plastering, boarding, roofing, and more.\n\n• Financial Services: Our financial advisors assist with mortgage advice for builds and renovations.`,
    image: '/images/ServicesData/projectPlanning.webp',
  },
  { 
    id: 2, 
    title: 'BUILDING & CONSTRUCTION', 
    description: 'Our building and construction services encompass project management, carpentry, roofing, plastering, and all necessary ground work.', 
    content: `We provide a full range of building and construction services, including carpentry, timber framing, roofing, slate work, plastering, and skimming. Whether you're looking to develop a new property or renovate an existing one, Lambert & Wright’s experienced team of builders and craftsmen ensure a high-quality finish, adhering to all building inspector requirements and building control advice. We also handle electrical installations, plumbing systems, and all essential ground works needed for any development project.`,
    image: '/images/ServicesData/Building&Construction.webp',
  },
  { 
    id: 3, 
    title: 'PLUMBING & ELECTRICAL', 
    description: 'We offer expert plumbing and electrical services, from heating system upgrades to bathroom installations and power system designs.', 
    content: `Our plumbing and electrical services cover all your home’s needs, including heating, radiators, hot water systems, and electrical installations. Whether you're upgrading a kitchen or bathroom, renovating drainage systems, or installing new electrics and power systems, our certified plumbers and electricians ensure every job is done to the highest standards.\n\nOur expertise includes:\n\n• Electrical Design & Installation\n\n• Kitchen & Bathroom Installations\n\n• Heating System Upgrades & Boiler Installations\n\n• Hot/Cold Pipework Alterations\n\n• Drainage Systems, Taps, Toilets, and Basins\n\n• Underfloor Heating and Radiators Installation\n\nWe also specialise in energy-efficient heating systems to enhance your home's comfort and efficiency.`,
    image: '/images/ServicesData/Plumbing&Electrical.png',
  },
  { 
    id: 4, 
    title: 'DESIGN', 
    description: 'From new kitchens and bathrooms to window and door upgrades, our design services make your renovation stress-free.', 
    content: `Our design services cover everything from interior decoration to bespoke kitchen and bathroom designs, ensuring your home reflects your unique style. We manage all aspects, including tiling, flooring (wooden floors, carpets), and window and door installations (bifold doors, Velux windows).\n\nOur design services include:\n\n• Interior Decorating\n\n• Kitchen and Bathroom Designs\n\n• Tiling, Flooring, and Carpets\n\n• Door and Window Upgrades (Lanterns, Bifold, Velux)\n\nLet our expert design team take the stress out of your home renovation with stunning, functional results.`,
    image: '/images/ServicesData/Design.webp',
  },
  { 
    id: 5, 
    title: 'PROPERTY EXTENSIONS', 
    description: 'Need more space? We specialise in home extensions, creating additional living areas, bedrooms, and kitchen spaces.', 
    content: `Extending your home can be a cost-effective alternative to moving. Whether you're after a larger kitchen, extra bedrooms, or a new living space, Lambert & Wright guide you through every stage of the extension process. We handle all planning, development, and ground works, ensuring all building control requirements are met.\n\nOur extensions services include:\n\n• Kitchens\n\n• Bedrooms\n\n• Living Areas\n\n• Bathroom & Kitchen Upgrades\n\nWe assist with the entire process, from planning applications to project completion, making sure you get the extra space your family needs without the hassle.`,
    image: '/images/ServicesData/PropertyExtensions.webp',
  },
  { 
    id: 6, 
    title: 'LANDSCAPING', 
    description: 'We deliver functional and creative landscaping services, from patios and driveways to garden design and water features.', 
    content: `Our landscaping services cover both hard and soft landscaping, including patios, driveways, garden maintenance, and water features. Whether you're looking for creative garden design or practical outdoor solutions, our team will bring your vision to life. We specialise in period restoration and using high-quality materials like lime mortar and ancient oak to preserve the authenticity of traditional homes.\n\nOur landscaping services include:\n\n• Patios, Driveways, Pathways\n\n• Garden Design and Maintenance\n\n• Water Features and Outdoor Lighting\n\n• Soft Landscaping (Planting, Lawns)\n\nTransform your outdoor space into a beautiful and functional area with our experienced landscaping team.`,
    images:[ 
      '/images/ServicesData/Landscaping1.webp',
      '/images/ServicesData/Landscaping2.webp',
    ]
  },
  { 
    id: 7, 
    title: 'BATHROOM & KITCHEN UPGRADES', 
    description: 'With over 45 years of experience, we offer bespoke bathroom and kitchen renovations tailored to your needs.', 
    content: `Our kitchen and bathroom renovations are designed to suit your personal needs. With over 45 years of experience in home refurbishments, Lambert & Wright is the top choice in Leicestershire for bespoke designs. Whether you need new tiling, modern appliances, or period restoration for a traditional look, our expert craftsmen will transform your kitchen or bathroom into a space you love.\n\nWe specialise in:\n\n• Tiling\n\n• Custom Worktops and Cabinetry\n\n• Bathroom & Kitchen Fixtures (Showers, Baths, Taps, Toilets)\n\n• Flooring & Carpentry\n\nWhether it's a full renovation or minor upgrades, we have the knowledge, skills, and craftsmanship to deliver a space that exceeds expectations.`,
    images: [
      '/images/Bathrooms/Bath%201.webp',
      '/images/Bathrooms/Bath%202.webp',
      '/images/Bathrooms/Bath%206.webp',
      '/images/Bathrooms/Bath3.webp',
      '/images/Bathrooms/Bath4.webp',
      '/images/Bathrooms/Bath5.webp',
      '/images/Bathrooms/Bumblebee%20Cottage,%20Main%20Street,%20Burton%20Overy-26.webp',
      '/images/Bathrooms/Bumblebee%20Cottage,%20Main%20Street,%20Burton%20Overy-36.webp',
      '/images/Bathrooms/Bumblebee%20Cottage,%20Main%20Street,%20Burton%20Overy-37.webp',
      '/images/Bathrooms/Bumblebee%20Cottage,%20Main%20Street,%20Burton%20Overy-38.webp',
      '/images/Bathrooms/Bumblebee%20Cottage,%20Main%20Street,%20Burton%20Overy-39.webp',
      '/images/Bathrooms/Bumblebee%20Cottage,%20Main%20Street,%20Burton%20Overy-40.webp',
    ]
  },
  { 
    id: 8, 
    title: 'HEATING INSTALLATION & UPGRADES', 
    description: 'Efficient heating systems are essential for energy-saving and comfort. We install underfloor heating, new boilers, and radiator upgrades.', 
    content: `Lambert & Wright provide expert heating installation and upgrades. Whether you need underfloor heating, a new boiler system, or radiator installation, we ensure your home’s heating is efficient and comfortable. We also offer thermostat installations and heating system repairs to keep your home running smoothly all year round.\n\nOur heating services include:\n\n• Underfloor Heating Installation\n\n• Boiler System Installation & Upgrades\n\n• Radiator Installation & Repairs\n\n• Thermostat Installation\n\nLet our heating experts guide you through the process of upgrading your home’s heating system for greater comfort and energy savings.`,
    image: '/images/ServicesData/heating.webp',
  },
];
